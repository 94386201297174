@import "swiper/css";
@import "normalize.css";
:root {
  // color-variables
  --button-color: #f9d641;
  --button-hover-color: #fce894;
  --button-click-color: #fbb040;
  --accent-color: #e8505b;
  --primary-color: #5075a1;
  --primary-text-color: #6d6d6d;
  --accent-text-color: #363636;
  --default-background-color: #ffffff;
  --primary-background-color: #fbfbfb;
  --accent-background-color: #e0e0e0;
  --accent-color-general: #577faf;
  //typography
  --font: "Open Sans", sans-serif;
  --radius: 8px;
}

.card {
  background-color: var(--primary-background-color);
  border-radius: 8px;
}

.has-hover:hover {
  border-radius: 8px;
  box-shadow: -1px 10px 30px 2px var(--accent-color-general);
  transition: box-shadow 0.6s ease-out;
}

// typography
@mixin h1 {
  font-family: var(--font);
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 80px;
}

@mixin h2 {
  font-family: var(--font);
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 64px;
}

@mixin h3 {
  font-family: var(--font);
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 64px;
}

@mixin h4 {
  font-family: var(--font);
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
}

@mixin h5 {
  font-family: var(--font);
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
}

@mixin h6 {
  font-family: var(--font);
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
}

@mixin factoid-sign {
  font-family: var(--font);
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
}

@mixin primary-text {
  font-family: var(--font);
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
}

@mixin accent-text {
  font-family: var(--font);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
}

@mixin usual-subheading-text {
  font-family: var(--font);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
}

@mixin accent-subheading-text {
  font-family: var(--font);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
}

@mixin usual-body-text {
  font-family: var(--font);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

@mixin small-body-text {
  font-family: var(--font);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

// buttons
button {
  color: var(--accent-text-color);
  &:enabled {
    cursor: pointer;
  }
  &:disabled {
    cursor: default;
  }
}

.primary-button {
  @include accent-subheading-text;
  background-color: var(--default-background-color);
  border: 2px solid var(--button-color);
  border-radius: 8px;
}

.special-button {
  @include accent-subheading-text;
  background-color: var(--default-background-color);
  border: 2px dashed var(--button-color);
  border-radius: 8px;
  &:hover {
    background-color: var(--button-hover-color);
    border: 2px solid var(--button-hover-color);
  }
  &:active {
    background-color: var(--button-click-color);
    border: 2px solid var(--button-click-color);
  }
}

@mixin button-wide {
  width: 176px;
  height: 52px;
}

.accent-button {
  @include accent-subheading-text;
  background-color: var(--button-color);
  border-radius: var(--radius);
  border: 2px solid var(--button-color);
  color: var(--accent-text-color);
  &:hover {
    background-color: var(--button-hover-color);
    border: 2px solid var(--button-hover-color);
  }
  &:active {
    background-color: var(--button-click-color);
    border: 2px solid var(--button-click-color);
  }
  &:disabled {
    background-color: var(--accent-background-color);
    border: 2px solid var(--accent-background-color);
  }
}

.swiper-slide:first-of-type .slide-container {
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.swiper-wrapper {
  margin-top: 11px;
  margin-left: -36px;
}
.slide-title {
  max-width: 182px;
}

// поля ввода
input {
  height: 40px;
}

input,
textarea {
  display: block;
  border: 1px solid var(--accent-background-color);
  border-radius: var(--radius);
  color: var(--primary-text-color);
  @include accent-subheading-text;
  padding: 8px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
}

input[type="checkbox"] {
  display: none;
}

label:focus-within {
  color: var(--primary-color);
}

label.error {
  color: var(--accent-color);
}

label.checkbox {
  display: inline-block;
  margin-left: 40px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: -40px;
    top: -18px;
    transition: all 0.5s;
    width: 20px;
    height: 20px;
    border: 1px solid var(--accent-background-color);
    border-radius: 4px;
  }
}

input[type="checkbox"]:checked + label.checkbox {
  &::before {
    border: 1px solid var(--primary-color);
    background-image: url(assets/images/tick.png);
    background-repeat: no-repeat;
    background-position: center;
  }
}

h2 {
  margin-top: 0;
  @include h6;
  color: var(--accent-text-color);
  text-align: center;
}

@media screen and (min-width: 1280px) {
  h2 {
    @include h4;
  }
}

div,
p,
h2,
span {
  cursor: default;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

@font-face {
  font-family: "Open Sans";
  src: url("/assets/fonts/OpenSans-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  font-stretch: 100%;
}

@font-face {
  font-family: "Open Sans";
  src: url("/assets/fonts/OpenSans-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  font-stretch: 100%;
}

@font-face {
  font-family: "Open Sans";
  src: url("/assets/fonts/OpenSans-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
  font-stretch: 100%;
}
